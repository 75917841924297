export const Data = {
  profile: {
    image: "./profile.jpg",
    name: "Alp Dogramaci",
    occupation: "",
    location: "Sydney, Australia",
    email: "me@alpdogramaci.com",
    phone: "",
    profileLabel: "Profile",
    profile: "",
    skillsLabel: "Skills",
    skills: [
      "Support",
      "Infrastructure",
    ],
    interestsLabel: "Interests",
    interests: [
      "IT",
      "",
      "",
    ],
    socialLabel: "Social",
    socials: [
      {
        label: "LinkedIn",
        name: "linkedin",
        url: "https://www.linkedin.com/in/alp-dogramaci-0b2143265",
        className: "bxl-linkedin-square",
      }
    ],
  },
  timeline: {
    experiences: [
      {
        title: "Service Desk and AV Technician",
        period: "Jul. 2023 - Present",
        company: "Hills Grammar",
        country: "AU",
        description: "Microsoft 365 Cloud Suite (Azure AD, Intune, Entra, Endpoint Protection, SharePoint, Teams, Power Apps, Power Automate) and On-Prem (Windows Server, AD, GPO, Clearpass, HPE).",
      },
      {
        title: "IT and Electronics Services Technician",
        period: "Jan. 2023 - May. 2023",
        company: "Interlink Services",
        country: "AU",
        description: "Perform asset management, hardware troubleshooting & various related duties",
      },
      {
        title: "Apprentice Mechanic",
        period: "Jun. 2022 - Nov. 2022",
        company: "Nissan Motor Corporation",
        country: "AU",
        description: "Maintenance & Repair · Stocking · Automotive · Automotive Repair · Mechanics",
      },
      {
        title: "Cashier",
        period: "Mar. 2018 - May. 2020",
        company: "Oporto",
        country: "AU",
        description: "Cashiering · Stocking · Customer Service",
      }
    ],
    educations: [
      {
        title: "Bachelor of Information Technology, Cybersecurity",
        period: "2023 - Present",
        institution: "Macquarie University",
        country: "AU",
        description: "WAM: 81.74 ~ GPA: 6.22",
        additionalInfo: [
          ""
        ]
      },
      {
        title: "Higher School Certificate",
        period: "2013 - 2021",
        institution: "The Hills Grammar School",
        country: "AU",
        description: "",
        additionalInfo: [
          ""
        ]
      },
    ],
    projects: [
      {
        name: "",
        company: "Personal",
        period: "",
        description: "",
        url: ""
      },
    ],
  },
};
